<template>
    <h2>{{ Get_Text_Lang(News_Text_Data.title) }}</h2>
    <span v-for="(info, index) in news_list" :key="index" @click="News_Link(info)">
        <div class="news_content">{{ index + 1 + "." + info["Content"] }}</div>
        <div class="time_box">
          {{ info["Time"] }}
        </div>
        <div class="hot_tag" :style="Get_Tag_Style(info)">{{ Get_Tag_Text(info) }}
        </div>
        <div class="news_divide"></div>
      </span>
</template>

<script>
import $ from "jquery";
export default {
    name:"vBox_news",
    props: ["lang","News_Text_Data"],
    data() {
        return {
            news_list: [],
        };
    },
    methods: {
        Get_Text_Lang(obj) {
            if (this.lang == "English") {
                return obj["en"];
            }
            return obj["cn"];
        },
        Get_Tag_Style(info){
          if(info['type'] == "paper"){
            return{
              backgroundColor:"#f5ab35"
            }
          }
          if(info['type'] == "lecture"){
            return{
              backgroundColor:"#61db29"
            }
          }
        },
        Get_Tag_Text(info){
          if(info['type'] == "paper"){
            return "Paper"
          }
          if(info['type'] == "lecture"){
            return "Lecture"
          }
        },
        News_Link(info){
          if(info["type"] == "lecture"){
            window.location.href = "/news_page.php?id="+info["Link"];
          }
         
        }

    },
    mounted() {
    const that = this;
    $.getJSON("/data/news.json", function (data) {
      that.news_list = data.slice(0, 8);
    });
  },
}
</script>

<style scoped>

.news_divide {
  width: 70%;
  height: 1px;
  background-color: #fbb;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

.time_box {
  /* background-color: aqua; */
  display: inline-block;
  height: 2vw;
  min-width: 4vw;
  margin-left: 0vw;
  margin-right: 30px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 30px;
  font-weight: 800;
  color: #aaa;
  vertical-align: top;
}

.hot_tag {
  display: inline-block;
  background-color: #61db29;
  color: white;
  vertical-align: top;
  padding: 5px;
  /* padding-left: 1vw; */
  /* padding-right: 1vw; */
  margin-top: 10px;
  margin-right: 50%;
  font-size: 14px;
  text-align: center;
  width: 60px;
}

.news_content {
  font-weight: 800;
  font-size: 18px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  line-height: 28px;

  /* word-break: break-all; */
  /* background-color: #555; */
  text-align: left;
}
</style>