<template>
  <div style="text-align: center">
    <!-- 照片显示部分 -->

    <div class="vbody">
      <div class="img_box" :style="Get_Current_Photo_Style()">
        <!-- <img :src="Get_Current_Photo()" alt="" class="img_display" /> -->
      </div>
    </div>
    <span class="text_box_pos" @click="Change_Pic()">
      <div class="text_box">
        <div class="text_title">{{ Get_Title_Text() }}</div>
        <div class="text_body" v-html="Get_Body_Text()"></div>
      </div>
    </span>

    <div class="space_occupy"></div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "photo_display",
  props: ["lang", "screen_type"],
  data() {
    return {
      screen_w: 0,
      screen_h: 0,
      image_choose_current: 0,
      photo_List: [],
    };
  },
  methods: {
    Change_Pic() {
      let id = this.image_choose_current + 1;
      if (id >= this.photo_List.length) {
        id = 0;
      }

      this.Choose_Image(id);
    },
    Get_Current_Photo() {
      if (this.photo_List.length == 0) {
        return;
      }
      return this.photo_List[this.image_choose_current]["path"];
    },
    Get_Current_Photo_Style() {
      if (this.photo_List.length == 0) {
        return;
      }
      return {
        backgroundImage:
          "url(" + this.photo_List[this.image_choose_current]["path"] + ")",
        width: "100vw",
        height: "50vw",
        backgroundSize: "100% 100%",
        transition: "all 0.5s",
      };
    },
    Get_Title_Text() {
      if (this.photo_List.length == 0) {
        return;
      }
      return this.photo_List[this.image_choose_current]["text_title"];
    },
    Get_Body_Text() {
      if (this.photo_List.length == 0) {
        return;
      }
      if (this.lang == "English") {
        return this.photo_List[this.image_choose_current]["text_body"];
      }
      return this.photo_List[this.image_choose_current]["text_body_CN"];
    },
    Jump_Img_Link() {
      if (this.photo_List.length == 0) {
        return;
      }
      window.location.href =
        this.photo_List[this.image_choose_current]["read_link"];
    },
    Choose_Image(index) {
      $(".text_box").removeClass("text_box_show");

      setTimeout(() => {
        $(".text_box").addClass("text_box_show");
        this.image_choose_current = index;
      }, 500);
    },
  },
  mounted() {
    $(".text_box").addClass("text_box_show");
    const that = this;
    $.getJSON("/data/index_pic.json", function (data) {
      that.photo_List = data;
      console.log(data);
    });
  },
};
</script>

<style scoped>
.vbody {
  /* display: inline-block; */
  position: absolute;
  /* background-color: rebeccapurple; */
  width: 100%;
  height: 850px;
  vertical-align: top;
  top: 12vw;
  left: 0pt;
  right: 0pt;
  z-index: -1;
  overflow: hidden;
  text-align: center;
}
.text_box {
  /* background-color: red; */
  background-color: rgba(1, 1, 1, 0);
  width: 50vw;
  height: 4vw;
  /* position: absolute; */
  /* margin-left: auto;
  margin-right: auto; */
  /* margin-left: 500px; */
  /* margin-right: 1vw; */

  /* display: inline-block; */
  text-align: center;
  transition: all 1s;
  /* float: right; */
  overflow: hidden;
  text-align: center;
}
.text_box_pos {
  /* background-color: red; */
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
  min-height: 32vw;
  display: inline-block;
  margin-top: 20vw;
}
.text_box_show {
  background-color: rgba(1, 1, 1, 0.4);
  height: 38vw;
  /* width: 50vw; */
}
.text_title {
  display: inline-block;
  background-color: #f5ab35;
  font-size: 15px;
  font-weight: 800;
  color: white;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
}
.text_body {
  color: #fff;
  /* background-color: blueviolet; */
  font-size: 12px;
  font-weight: 800;
  display: inline-block;
  width: 40vw;
  margin-top: 5vw;
  text-shadow: 5px 5px 5px #999;
}
.text_link {
  color: #f5ab35;
  font-size: 1vw;
  font-weight: 600;
  margin-top: 1vw;
  text-decoration: underline;
  text-shadow: 5px 5px 4px #bbb;
  transition: all 0.5s;
}
.text_link:hover {
  color: yellow;
}
.space_occupy {
  display: inline-block;
  height: 50px;
  width: 100%;
  /* background-color: bisque; */
  z-index: 2;
  margin: 0px;
}
#vue_slider_choose {
  /* background-color: rebeccapurple; */
  /* display: inline-block; */
  position: absolute;
  /* margin: auto; */
  /* margin-top: 20vw; */
  top: 38vw;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  text-align: center;
  width: 100vw;
  height: 170px;
}
.img_box {
  /* background-image: url(); */
  /* display: inline-block; */
  /* width: "100%";
  height: "100%"; */
  /* background-size: 100% 100%; */
  /* transition: "all"; */
  /* overflow: hidden; */
}
.img_display {
  width: 100%;
  height: 100%;
  transition: all 2s;
}
.slide_box {
  width: 73vw;
  height: 170px;
  overflow: hidden;
  /* background-color: aqua; */
  display: inline-block;
  vertical-align: top;
  border-bottom: solid 6px #f5ab35;
}

.slide_btn {
  width: 5vw;
  height: 5vw;
  display: inline-block;
  /* background-color: blue; */
  margin: 1vw;
  margin-top: 65pt;
  vertical-align: top;
}

.slide_btn_L {
  background: url("../../public/v_image/photodisplay/向左.png");
  background-size: 100% 100%;
}

.slide_btn_R {
  background: url("../../public/v_image/photodisplay/向右.png");
  background-size: 100% 100%;
}

.slider_transform {
  display: inline-block;
  width: 1070pt;
  height: 160pt;
  transition: all 1s;
}

.slider_item {
  display: inline-block;
  background-color: black;
  width: 180pt;
  height: 130pt;
  margin: 15pt;
  margin-right: 10pt;
  vertical-align: top;
  transition: all 0.5s;
}

.slider_item_show_box {
  display: inline-block;
  width: 160pt;
  height: 100pt;
  background-color: aliceblue;
  margin: 10pt;
  background-size: 100% 100%;
}
</style>