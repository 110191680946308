<template>
  <div style="text-align: center;">
    <!-- 照片显示部分 -->
    <div class="vbody">
      <div class="img_box" :style="Get_Current_Photo_Style()">
        <!-- <img :src="Get_Current_Photo()" alt="" class="img_display" /> -->
        <img class="img_box_img_style" :src="Get_Current_Photo_SRC()" alt="" referrerPolicy="no-referrer"
          style="width: 110%; height: 110%" :style="Get_Img_Offset_Style()" />
        <!-- {{ img_offset_x }} -->
      </div>
    </div>
    
    <vCenter_Text_Box
    :photo_List="photo_List"
    :lang="lang"
    :image_choose_current_trans="image_choose_current"
    ></vCenter_Text_Box>
    <!-- 滑动选择部分 传入图片列表，子组件通知函数 -->

    <vSlider_Choose_Box 
    :photo_List="photo_List"
    :Choose_Image_Message="Choose_Image"
    ></vSlider_Choose_Box>
    <div class="space_occupy"></div>
  </div>
</template>

<script>
import $ from "jquery";
import vSlider_Choose_Box from "./vSlider_Choose_Box.vue"
import vCenter_Text_Box from "./vCenter_Text_Box.vue"
export default {
  name: "photo_display",
  components:{
    vSlider_Choose_Box,
    vCenter_Text_Box
  },
  props: ["lang", "screen_type"],
  

  data() {
    return {
      screen_w: 0,
      screen_h: 0,
      image_choose_current: 0,
      
      photo_List: [],

      img_offset_x: 0,
      img_offset_y: 0,
      img_scale: 1,

      mouse_pos_before_x: 0,
      mouse_pos_before_y: 0
    };
  },
  methods: {
    slide(offset) {
      if (this.slider_trans_offset + offset > 0) {
        return;
      }
      if (
        this.slider_trans_offset + offset <
        -(this.photo_List.length - 5) * 205
      ) {
        return;
      }

      this.slider_trans_offset += offset;
    },

    Get_Current_Photo() {
      if (this.photo_List.length == 0) {
        return;
      }
      return this.photo_List[this.image_choose_current]["path"];
    },
    Get_Current_Photo_Style() {
      if (this.photo_List.length == 0) {
        return;
      }
      return {
        // backgroundImage:
        //   "url(" + this.photo_List[this.image_choose_current]["path"] + ")",
        width: "100vw",
        height: "50vw",
        backgroundSize: "100% 100%",
        transition: "all 0.5s",
      };
    },
    Get_Current_Photo_SRC() {
      if (this.photo_List.length == 0) {
        return;
      }

      return this.photo_List[this.image_choose_current]["path"];
    },
    Get_Img_Offset_Style() {
      return {
        //img_offset_x
        "transform": `translate(${this.img_offset_x - 40}px,${this.img_offset_y + 0}px) scale(${this.img_scale},${this.img_scale})`
      }
    },

    
    Choose_Image(index) {
      console.log(index);
      this.image_choose_current = index;

    },
    Change_From_To(from,to,speed,error){
      if(Math.abs(from-to)<=error){
        return to;
      }


      if(from>to){
        from-=speed;
      }
      if(from<to){
        from+=speed;
      }

      return from;
    }
  },
  mounted() {
    $(".text_box").addClass("text_box_show");
    const that = this;
    $.getJSON("/data/index_pic.json", function (data) {
      that.photo_List = data;
      console.log(data);
    });
    setInterval(() => {
      that.img_offset_x = this.Change_From_To(that.img_offset_x,0,0.5,0.5)
      that.img_offset_y = this.Change_From_To(that.img_offset_y,0,0.5,0.5)


      // that.img_offset_x = that.img_offset_x * 0.98;
      // that.img_offset_y = that.img_offset_y * 0.98;

      let scale_offset =
        (Math.abs(that.img_offset_y) + Math.abs(that.img_offset_x)) * 0.001


      that.img_scale = 1 + scale_offset
    }, 100);


    document.onmousemove = function (event) {
      event = event || window.event;

      let curr_x = event.clientX;
      let curr_y = event.clientY;

      if (curr_x > that.mouse_pos_before_x) {
        that.img_offset_x += 0.2
      } else if (curr_x < that.mouse_pos_before_x) {
        that.img_offset_x -= 0.2
      }


      if (curr_y > that.mouse_pos_before_y) {

        that.img_offset_y += 0.2
      } else if (curr_y < that.mouse_pos_before_y) {
        that.img_offset_y -= 0.2

      }

      if (that.img_offset_x > 20) {
        that.img_offset_x = 20
      }
      if (that.img_offset_x < -20) {
        that.img_offset_x = -20
      }

      if (that.img_offset_y > 20) {
        that.img_offset_y = 20
      }
      if (that.img_offset_y < -20) {
        that.img_offset_y = -20
      }



      that.mouse_pos_before_x = event.clientX;
      that.mouse_pos_before_y = event.clientY;

    }
  },
};
</script>

<style scoped>
.vbody {
  /* display: inline-block; */
  position: absolute;
  /* background-color: rebeccapurple; */
  width: 100%;
  height: 850px;
  vertical-align: top;
  top: 0pt;
  left: 0pt;
  right: 0pt;
  z-index: -1;
  overflow: hidden;
  text-align: center;
}









.space_occupy {
  display: inline-block;
  height: 50px;
  width: 100%;
  /* background-color: bisque; */
  z-index: 2;
  margin: 0px;
}



.img_box {
  /* background-image: url(); */
  /* display: inline-block; */
  /* width: "100%";
  height: "100%"; */
  /* background-size: 100% 100%; */
  /* transition: "all"; */
  /* overflow: hidden; */
}

.img_box_img_style {
  /* transform: translate(0px,0px); */
  /* object-fit: cover; */
  transition: all 0.1s;
  /* transform: scale(2,20); */
}

.img_display {
  width: 100%;
  height: 100%;
  transition: all 2s;
}



.slide_btn {
  width: 5vw;
  height: 5vw;
  display: inline-block;
  /* background-color: blue; */
  margin: 1vw;
  margin-top: 65pt;
  vertical-align: top;
}



</style>