<template>
  <div class="nav_root" :style="nav_root_style()">
    <span v-bind:class="transform_box_css_class()">
      <div class="title_pic">
        <img
          src="/v_image/vNav/smartvip1.png"
          alt=""
          style="width: 100%; height: 100%"
        />
      </div>
      <div class="lang_toggle" v-on:click="Toggle_Language()">
        {{ language_choose }}
      </div>
      <br />
      <div class="link_box">
        <div
          v-show="language_choose == `English`"
          class="link_item_box"
          v-for="(info, index) in Link_List_EN"
          :key="index"
          @click="Link_Jump(info['link'])"
        >
          <div class="link_text">{{ info["name"] }}</div>
          <div class="link_style">
            <div class="line_effect"></div>
          </div>
        </div>
        <div
          v-show="language_choose == `中文`"
          class="link_item_box"
          v-for="(info, index) in Link_List_CN"
          :key="index"
          @click="Link_Jump(info['link'])"
        >
          <div class="link_text">{{ info["name"] }}</div>
          <div class="link_style">
            <div class="line_effect"></div>
          </div>
        </div>
      </div>
    </span>
  </div>
</template>

<script>
import $ from "jquery";
export default {
  name: "vNav",
  props: ["screen_type"],
  data() {
    return {
      language_choose: "English",
      nav_state: 0,
      Link_List_EN: [
        {
          name: "Home",
          link: "index.html",
        },
        {
          name: "Team",
          link: "Team.html",
        },
        {
          name: "Paper",
          link: "publication.html",
        },
        {
          name: "News",
          link: "news.html",
        },
        {
          name: "Life",
          link: "life.html",
        },
        {
          name: "Literature",
          link: "",
        },
        {
          name: "About Us",
          link: "about.html",
        },
      ],
      Link_List_CN: [
        {
          name: "主页",
          link: "index.html",
        },
        {
          name: "团队",
          link: "Team.html",
        },
        {
          name: "文章发表",
          link: "publication.html",
        },
        {
          name: "团队新闻",
          link: "news.html",
        },
        {
          name: "日常生活",
          link: "life.html",
        },
        {
          name: "书籍著作",
          link: "",
        },
        {
          name: "关于我们",
          link: "about.html",
        },
      ],
    };
  },
  methods: {
    windowScroll() {
      let scrollTop = document.documentElement.scrollTop;
      if (scrollTop > 150) {
        this.nav_state = 1;
      } else {
        this.nav_state = 0;
      }
    },
    transform_box_css_class() {
      if (this.nav_state == 0) {
        return "transform_box";
      }
      return "transform_box_effect";
    },
    nav_root_style() {
      if (this.nav_state == 0) {
        return {
          background: "#fff0",
        };
      }
      return {
        background: "#000",
      };
    },
    Link_Jump(link) {
      window.location.href = link;
    },
    Toggle_Language() {
      console.log(this.language_choose);
      if (this.language_choose == "English") {
        this.language_choose = "中文";
      } else {
        this.language_choose = "English";
      }
      this.Language_Submit();
    },
    Language_Submit() {
      this.$emit("Lan_Submit", this.language_choose); // 子向父
    },
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
    let that=this;
    $.getJSON("/data/Nav_link.json", function (data) {
      that.Link_List_EN = data.Link_List_EN;
      that.Link_List_CN = data.Link_List_EN;
    });



  },
};
</script>

<style scoped>
.nav_root {
  position: fixed;
  width: 100vw;
  min-height: 2vw;
  /* aspect-ratio: 1850/8; */
  background-color: black;
  top: 0pt;
  left: 0pt;
  right: 0pt;
  /* display: inline-block; */
  /* float: left; */
  transition: all 2s;
  z-index: 10;
}

.transform_box {
  /* background-color: blue; */

  display: inline-block;
  transform: translateY(30pt);
  width: 100%;
  height: 5vw;
  transition: all 2s;
  text-align: right;
}
.transform_box_effect {
  display: inline-block;
  transform: translateY(0pt);
  width: 100%;
  height: 5vw;
  /* background-color: black; */
  transition: all 2s;
  text-align: right;
}

.title_pic {
  /* display: inline-block; */
  /* width: 350px; */
  width: 20vw;
  /* height: 80px; */
  /* background-color: aqua; */
  float: left;
  margin-left: 5vw;
  margin-top: 0vw;
}
.lang_toggle {
  width: 5vw;
  height: 1.8vw;
  background-color: rgba(255, 255, 255, 0.7);
  /* float: right; */
  margin-right: 3vw;
  display: inline-block;
  font-size: 1vw;
  font-weight: 800;
  line-height: 1.8vw;
  text-align: center;
  /* margin-top: 0px; */
  /* vertical-align: top; */
}
.link_box {
  /* background-color: chartreuse; */
  min-width: 40vw;
  height: 2.5vw;
  /* float: right; */
  margin-right: 10vw;
  margin-top: 0.3vw;
  text-align: left;
  display: inline-block;
}

.link_item_box {
  vertical-align: top;
  /* background-color: red; */
  display: inline-block;
  height: 1.2vw;
  min-width: 1vw;
  margin-right: 1.5vw;
  padding-left: 0.5vw;
  padding-right: 0.5vw;
  margin-bottom: 3vw;
}

.link_item_box:hover > .link_style > .line_effect {
  width: 4vw;
}

.link_text {
  font-size: 1vw;
  font-weight: 800;
  color: #fff;
}

.link_style {
  width: 40pt;
  height: 10pt;
  margin-top: 10pt;
  /* background-color:black; */
}

.line_effect {
  background-color: #ffae00;
  height: 0.1vw;
  width: 0vw;
  transition: all 1s;
}
</style>