<template>
    <div class="loc_body">
        <h2>{{ Get_Text_Lang(RA_Text_Data.title) }}</h2>
        <span>
            <img src="/v_image/main_box/1.jpg" alt="" class="research_img" />
            <div class="img_title">{{ Get_Text_Lang(RA_Text_Data.subitem[0].main) }}</div>
            <div class="img_detail">{{ Get_Text_Lang(RA_Text_Data.subitem[0].detail) }}</div>
        </span>
        <span>
            <img src="/v_image/main_box/2.jpg" alt="" class="research_img" />
            <div class="img_title">{{ Get_Text_Lang(RA_Text_Data.subitem[1].main) }}</div>
            <div class="img_detail">{{ Get_Text_Lang(RA_Text_Data.subitem[1].detail) }}
            </div>
        </span>
        <span>
            <img src="/v_image/main_box/3.jpg" alt="" class="research_img" />
            <div class="img_title">{{ Get_Text_Lang(RA_Text_Data.subitem[2].main) }}</div>
            <div class="img_detail">{{ Get_Text_Lang(RA_Text_Data.subitem[2].detail) }}</div>
        </span>

    </div>
</template>

<script>

export default {
    
    name: "vBox_RA",
    props: ["lang","RA_Text_Data"],
    data() {
        return {
           
        };
    },
    methods: {
        Get_Text_Lang(obj) {
            if (this.lang == "English") {
                return obj["en"];
            }
            return obj["cn"];
        }
    },
   
}
</script>

<style scoped>
.loc_body{
    /** */
}
.research_img:hover {
    transform: scale(1.05) rotate3d(1, 1, 0, 10deg);

}

.img_title {
    font-weight: 800;
    font-size: 16px;
}

.img_detail {
    font-weight: 100;
    font-size: 12px;
    color: #555;
    margin-top: 12px;
    margin-bottom: 40px;
}
.research_img {
  width: 90%;

  height: 17vw;
  min-height: 120px;

  transition: all 1s;
  box-shadow: 7px 7px 7px #afafaf;
}
</style>