<template>
  <div class="nav_root">
    <div class="pic_box">
      <img src="/v_image/vNav/smartvip1.png" alt="" class="img_title" />
    </div>
    <div class="Language_change" v-on:click="Toggle_Language()">
      {{ Get_Lan_Display() }}
    </div>
    <div class="menu" @click="Menu_Toggle()">
      <div class="menu_effect"></div>
      <div class="menu_effect"></div>
      <div class="menu_effect"></div>
    </div>
    <div
      v-if="language_choose == 'English'"
      class="pop_menu"
      :style="Get_pop_menu_style()"
    >
      <div
        class="menu_item"
        v-for="(info, index) in Link_List_EN"
        :key="index"
        @click="Link_Jump(info['link'])"
      >
        {{ info["name"] }}
      </div>
    </div>
    <div v-else class="pop_menu" :style="Get_pop_menu_style()">
      <div
        class="menu_item"
        v-for="(info, index) in Link_List_CN"
        :key="index"
        @click="Link_Jump(info['link'])"
      >
        {{ info["name"] }}
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "vNav_mobile",
  props: ["screen_type"],
  data() {
    return {
      language_choose: "English",
      menu_state: 1,
      Link_List_EN: [
        {
          name: "Home",
          link: "index.html",
        },
        {
          name: "Team",
          link: "Team.html",
        },
        {
          name: "Paper",
          link: "publication.html",
        },
        {
          name: "News",
          link: "news.html",
        },
        {
          name: "Life",
          link: "life.html",
        },
        {
          name: "Literature works",
          link: "",
        },
        {
          name: "About Us",
          link: "about.html",
        },
      ],
      Link_List_CN: [
        {
          name: "主页",
          link: "index.html",
        },
        {
          name: "团队",
          link: "Team.html",
        },
        {
          name: "文章发表",
          link: "publication.html",
        },
        {
          name: "团队新闻",
          link: "news.html",
        },
        {
          name: "日常生活",
          link: "life.html",
        },
        {
          name: "书籍著作",
          link: "",
        },
        {
          name: "关于我们",
          link: "about.html",
        },
      ],
    };
  },
  methods: {
    Link_Jump(link) {
      window.location.href = link;
    },
    Get_Lan_Display() {
      if (this.language_choose == "English") {
        return "EN";
      }
      return "CN";
    },
    Toggle_Language() {
      console.log(this.language_choose);
      if (this.language_choose == "English") {
        this.language_choose = "中文";
      } else {
        this.language_choose = "English";
      }
      this.Language_Submit();
    },
    Language_Submit() {
      this.$emit("Lan_Submit", this.language_choose); // 子向父
    },
    Menu_Toggle() {
      if (this.menu_state == 0) {
        this.menu_state = 1;
      } else {
        this.menu_state = 0;
      }
    },
    Get_pop_menu_style() {
      if (this.menu_state == 0) {
        return {
          height: "280px",
        };
      } else {
        return {
          height: "0px",
        };
      }
    },
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll); //监听页面滚动
    let that=this;
    $.getJSON("/data/Nav_link.json", function (data) {
      that.Link_List_EN = data.Link_List_EN;
      that.Link_List_CN = data.Link_List_EN;
    });
  },
};
</script>

<style scoped>
.nav_root {
  position: fixed;
  width: 100vw;
  min-height: 12vw;
  /* aspect-ratio: 1850/8; */
  background-color: #333;
  top: 0pt;
  left: 0pt;
  right: 0pt;
  /* display: inline-block; */
  /* float: left; */
  transition: all 2s;
  z-index: 10;
}
.pic_box {
  /* background: url("/v_image/vNav/smartvip1.png"); */
  /* background-color: red; */
  display: inline-block;
  width: 38vw;
  /* height: 16vw; */
}
.img_title {
  width: 100%;
  margin: 2vw;
  margin-left: 4vw;
}
.Language_change {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  margin-left: 30%;
  /* background-color: red; */
  height: 12vw;
  width: 12vw;
  text-align: center;
  line-height: 12vw;
  font-size: 20px;
}
.menu {
  display: inline-block;
  height: 10vw;
  width: 12vw;
  vertical-align: top;
  /* background-color: blue; */
  margin-left: 6%;
  line-height: 12vw;
  text-align: center;
  padding-top: 8px;
}
.menu_effect {
  /* display: inline-block; */
  width: 60%;
  height: 2px;
  background-color: white;
  margin-top: 6px;
}
.pop_menu {
  /* position: fixed; */
  width: 100%;
  /* margin-top: 12vw; */
  /* height: 400px; */
  overflow: hidden;
  transition: all 0.5s;
  /* background-color: red; */
}
.menu_item {
  height: 40px;
  width: 100%;
  background-color: #333;
  /* background-color: aqua; */

  display: inline-block;
  margin: 0px;
  text-align: center;
  color: #fff;
  text-align: center;
  /* z-index: 100; */
}
</style>