<template>
    <div>

    </div>
</template>

<script>
export default{
    name:"vBox_Activity"
}
</script>

<style scoped>
</style>