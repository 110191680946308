<template>
  <div class="main_box">
    <div class="box_left">

      <!-- 预留部分 -->
      <div class="box_activity">
        <div class="border_effect"></div>
        <vBox_Activity></vBox_Activity>
      </div>
      <div style="height: 2vw; display: none;" ></div>
      <!-- 预留部分END -->

      <div class="box_RA">
        <div class="border_effect"></div>
        <vBox_RA :lang="lang" :RA_Text_Data="RA_Text_Data"/>

      </div>


    </div>
    <div class="box_news">
      <div class="border_effect"></div>
      <vBox_news :lang="lang" :News_Text_Data ="News_Text_Data"/>

      
    </div>
  </div>
</template>

<script>

import vBox_Activity from "./vBox_Activity.vue";
import vBox_RA from "./vBox_RA.vue";
import vBox_news from "./vBox_news.vue";

export default {
  components: {
    vBox_Activity, vBox_RA,vBox_news
  },
  name: "Main_Box",
  props: ["lang"],
  data() {
    return {
      
      RA_Text_Data: {
        title: {
          "cn": "研究领域",
          "en": "Research Areas"
        },
        subitem: [
          {
            "main": {
              "cn": "机器学习与计算机视觉",
              "en": "Machine Learning and Computer Vision"
            },
            "detail": {
              "cn": "深度学习、深度交叉模型、人员/车辆再识别...",
              "en": "Deep learning、Deep Cross-model、Person/Vehicle Re-identification…"
            }
          },
          {
            "main": {
              "cn": "视频处理",
              "en": "Video Processing"
            },
            "detail": {
              "cn": "视频质量评估，检测，降噪...",
              "en": "Video quality assessment, demosacing, denosing…"
            }
          },
          {
            "main": {
              "cn": "视频编码",
              "en": "Video Coding"
            },
            "detail": {
              "cn": "VVC、H.266/H.265、屏幕内容/3D数据压缩...",
              "en": "VVC、H.266/H.265、Screen content/3D data compression..."
            }
          }
        ]
      },
      News_Text_Data: {
        title: {
          "cn": "最新成就",
          "en": "Recent News"
        }
      }
    };
  },
  methods: {

  },
  
};
</script>

<style scoped>


.box_activity {
  width: 50vw;/*随屏幕变化*/ 
  min-width: 340px;/**限定最小，适配手机 */
  min-height: 20vw;
  /* background-color: red; */
  border: solid 2px #ddd;
  display: none;
}

.box_RA {
  border: solid #ddd 2px;
  width: 50vw;/*随屏幕变化*/ 
  min-width: 340px;/**限定最小，适配手机 */
}


.main_box {
  width: 100vw;
  min-height: 1400px;
  /* background-color: aqua; */
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 0vw;
}

.box_left {
  display: inline-block;
  /* background-color: blue; */
  width: 50vw;
  min-height: 70vw;
  vertical-align: top;
  margin: 1%;

  min-width: 340px;
}

.border_effect {
  position: absolute;
  width: 100px;
  height: 4px;
  /* display: inline-block; */
  float: left;
  background-color: coral;
  transform: translate(40px, -3px);
}

.box_left>h2 {
  text-align: center;
}

.box_news {
  display: inline-block;
  /* background-color: red; */
  width: 22vw;
  min-width: 340px;
  min-height: 70vw;
  vertical-align: top;
  margin: 1%;
  border: solid #ddd 2px;
}
</style>