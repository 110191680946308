<template>
  <span>
    <!-- <vEffect_mobile v-if="screen_type != 'pc'"></vEffect_mobile> -->
    <vNav @Lan_Submit="Language_Change" v-if="screen_type == 'pc'"></vNav>
    <vNav_mobile @Lan_Submit="Language_Change" v-else></vNav_mobile>
    <photo_display :lang="language" v-if="screen_type == 'pc'"></photo_display>
    <Photo_Display_mobile :lang="language" v-else></Photo_Display_mobile>
    <Main_Box :lang="language"></Main_Box>
  </span>
</template>

<script>
import vNav from "./components/vNav.vue";
import vNav_mobile from "./components/vNav_mobile.vue";
import photo_display from "./components/Photo_Display.vue";
import Photo_Display_mobile from "./components/Photo_Display_mobile.vue";
import Main_Box from "./components/Main_Box.vue";
// import vEffect_mobile from "./components/vEffect_mobile.vue";
export default {
  name: "App",
  components: {
    vNav,
    vNav_mobile,
    photo_display,
    Photo_Display_mobile,
    Main_Box,
    // vEffect_mobile
  },
  data() {
    return {
      language: "English",
      screen_type: "pc",
    };
  },
  mounted() {
    setInterval(() => {
      if (window.screen.availWidth < 768) {
        this.screen_type = "phone";
      } else {
        this.screen_type = "pc";
      }
    }, 1000);
  },
  methods: {
    Language_Change(lan) {
      this.language = lan;
      console.log("-----------------", lan);
    },
  },
};
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
