<template>
    <div id="vue_slider_choose">
      <!-- <div class="slide_btn slide_btn_L" v-on:click="slide(205)"></div> -->
      <div class="slide_box">
        <span class="slider_transform" :style="{
          // width: photo_List.length * 205 + `pt`,
          // transform: `translateX(${slider_trans_offset}pt)`,
        }">
          <div class="slider_item" v-for="(info, index) in photo_List" :key="index" @click="Choose_Image(index)"
            :style="Get_Slider_Item_Border_Effect_Style(index)">
            <!-- referrerPolicy="no-referrer" -->
            <div class="slider_item_show_box">
              <img :src="info['path']" alt="" referrerPolicy="no-referrer" style="width: 100%; height: 100%" />
            </div>
          </div>
        </span>
      </div>

      <!-- <div class="slide_btn slide_btn_R" v-on:click="slide(-205)"></div> -->
    </div>
</template>
<script>
// import $ from "jquery";

export default {
  name: "vSlider_Choose_Box",
  props: ["photo_List","Choose_Image_Message"],
  data() {
    return {
        image_choose_current:0,
        slider_trans_offset: 0,
    };
  },
  methods:{
    Get_Slider_Item_Border_Effect_Style(index) {
      if (index == this.image_choose_current) {
        return {
          backgroundColor: "#f5ab35",
        };
      }
      return {
        backgroundColor: "#444",
      };
    },
    Choose_Image(index){
        this.image_choose_current = index;
        this.Choose_Image_Message(index);
    }
    
  }
};

</script>
<style scoped>
#vue_slider_choose {
  /* background-color: rebeccapurple; */
  /* display: inline-block; */
  /* position: absolute; */
  /* margin: auto; */
  /* margin-top: 20vw; */
  /* top: 38vw; */
  /* bottom: 0; */
  /* left: 0; */
  /* right: 0; */
  z-index: 2;
  text-align: center;
  width: 100vw;
  height: 170px;
}
.slide_box {
  width: 73vw;
  height: 10vw;
  overflow: hidden;
  /* background-color: aqua; */
  display: inline-block;
  vertical-align: top;
  border-bottom: solid 6px #f5ab35;
}

.slide_btn_L {
  background: url("../../public/v_image/photodisplay/向左.png");
  background-size: 100% 100%;
}

.slide_btn_R {
  background: url("../../public/v_image/photodisplay/向右.png");
  background-size: 100% 100%;
}

.slider_transform {
  display: inline-block;
  width:100%;
  height: 10vw;
  transition: all 1s;
}
.slider_item {
  display: inline-block;
  background-color: black;
  width: 12.6vw;
  height: 10vw;
  margin: 1vw;
  margin-right: 0.5vw;
  margin-left: 0.5vw;
  vertical-align: top;
  transition: all 0.5s;
  text-align: center;
  padding-top: 1vw;
}

.slider_item_show_box {
  display: inline-block;
  width: 86%;
  height: 72%;
  /* background-color: aliceblue; */
  vertical-align:middle;
  background-size: 100% 100%;
}
</style>









