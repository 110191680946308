<template>
    <span class="text_box_pos">
      <div class="text_box">
        <div class="text_title">{{ Get_Title_Text() }}</div>
        <div class="text_body" v-html="Get_Body_Text()"></div>
        <div class="text_link" @click="Jump_Img_Link()">CONTINUE READING</div>
      </div>
    </span>
</template>
<script>
import $ from "jquery";

export default {
    name:"vCenter_Text_Box",
    props: ["photo_List","lang","image_choose_current_trans"],
    data(){
        return {
            image_choose_current:0
        }
    },
    methods:{
        Get_Title_Text() {
        if (this.photo_List.length == 0) {
            return;
        }
        return this.photo_List[this.image_choose_current]["text_title"];
        },
        Get_Body_Text() {
        if (this.photo_List.length == 0) {
            return;
        }
        if (this.lang == "English") {
            return this.photo_List[this.image_choose_current]["text_body"];
        }
        return this.photo_List[this.image_choose_current]["text_body_CN"];
        },
        Jump_Img_Link() {
        if (this.photo_List.length == 0) {
            return;
        }
        window.location.href =
            this.photo_List[this.image_choose_current]["read_link"];
        },
    },
    watch:{
        image_choose_current_trans(val){
            $(".text_box").removeClass("text_box_show");

            setTimeout(() => {
                $(".text_box").addClass("text_box_show");
                this.image_choose_current = val;
            }, 500);
        }
    }

};

</script>
<style scoped>
.text_box_pos {
  /* background-color: red; */
  /* margin-left: auto; */
  /* margin-right: auto; */
  width: 30vw;
  min-height: 30vw;
  display: inline-block;
  margin-top: 8vw;
}
.text_box {
  /* background-color: red; */
  background-color: rgba(1, 1, 1, 0);
  width: 30vw;
  height: 4vw;
  /* position: absolute; */
  /* margin-left: auto;
  margin-right: auto; */
  /* margin-left: 500px; */
  /* margin-right: 1vw; */

  /* display: inline-block; */
  text-align: center;
  transition: all 1s;
  /* float: right; */
  overflow: hidden;
  text-align: center;
}
.text_title {
  display: inline-block;
  background-color: #f5ab35;
  font-size: 1vw;
  font-weight: 800;
  color: white;
  padding: 10px;
  padding-left: 25px;
  padding-right: 25px;
}

.text_body {
  color: #fff;
  /* background-color: blueviolet; */
  font-size: 1.7vw;
  font-weight: 800;
  display: inline-block;
  width: 30vw;
  margin-top: 5vw;
  text-shadow: 5px 5px 5px #999;
}

.text_link {
  color: #f5ab35;
  font-size: 1vw;
  font-weight: 600;
  margin-top: 1vw;
  text-decoration: underline;
  text-shadow: 5px 5px 4px #bbb;
  transition: all 0.5s;
}

.text_link:hover {
  color: yellow;
}
.text_box_show {
  background-color: rgba(1, 1, 1, 0.4);
  height: 26vw;
}
</style>






































